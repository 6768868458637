<template>
    <div class="card">
        <div class="card-inner">
            <div class="card-title-group align-start mb-2">
                <div class="card-title">
                    <h6 class="title"></h6>
                </div>
            </div>
            <div v-if="loaded">
              <apexchart :options="chartOptions" :series="series"></apexchart>
            </div>
        </div>
    </div><!-- .card -->
</template>

<script>
export default {
    props: {
        widgetFetcher: {
            type: Object
        },
        needsRefresh: {
          type: Boolean
        }
    },
    watch : {
        needsRefresh:function() {
          this.getStats()
        }
    },
    data() {
        return {
          ticketStats: null,
          loaded: false,
          series: [],
          chartOptions: {
            colors: ['#1EE0AC','#FF5C5C'],
            fill: {
              colors: ['#1EE0AC','#FF5C5C']
            },
            dataLabels: {
              enabled: false,
              style: {
                colors: ['#1EE0AC','#FF5C5C']
              }
            },
            labels: [],
            markers: {
              colors: ['#1EE0AC','#FF5C5C']
            },
            chart: {
              width: 200,
              height: 250,
              type: 'line'
            },
            stroke: {
              curve: 'smooth'
            },
            yaxis: {
              title: {
                text: 'Value'
              }
            },
            xaxis: {
              categories:  [],
              title: {
                text: 'Year'
              }
            },
            tooltip: {
              x: {
                show:false
              },
            },
          },
        }
    },
    created() {
        this.getStats()
    },
    methods:{
        getStats() {
            this.loaded = false
            this.$http.post('/widgets/Get_AssetDepreciationChart/', this.widgetFetcher)
            .then((response) => {
                //this.ticketStats = response.data
                //let xaxis  = [] 
                let series  = [{name: 'Value', data: []}, {name: 'Accumalated Depreciation', data: []}]
                response.data.forEach(element => {
                    //xaxis.push(element.year)
                    this.chartOptions.xaxis.categories.push(element.year)
                    series[0].data.push(element.depreciatedValue)
                    series[1].data.push(element.accumalatedDepreciation)
                    //alert(element.year)
                });
                //this.chartOptions.xaxis.categories = xaxis
                this.series = series
                this.series[1].type = 'area'
                this.loaded = true
            })
        }
    }
}
</script>

<style>

</style>