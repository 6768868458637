<template>
  <div>
      <div  id="attachments" style="position:relative;" >
                  <div class="row">
                    <div class="col-md-12" >
                     <div class="attachmentContainer" style="border-radius:10px">
                         <div class="text-center btn-dark text-white"  v-on:click="openFileSelect()" style="cursor:pointer; border-radius: 5px;padding: 15px;margin:20px;"><p><em class="icon ni ni-upload"></em> CLICK HERE TO ADD AN ATTACHMENT</p></div>
                        <div class="row">
                                <div class="col-md-12" >
                                    <input   type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" 
                                    style="display:none" class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="fileUpload" accept=".pdf,.jpg,.jpeg,.png" />
                                </div> 
                        </div>
                        <div class="row"  v-if="attachments">
                            <div class="col-12" style="padding: 0px 30px;" >
                                <div class="nk-tb-list is-separate mb-3">
                                        <div class="nk-tb-item "  v-for="attachment in attachments" v-bind:key="attachment.id">
                                             
                                            <div class="nk-tb-col">
                                                <a>
                                                    <div class="user-card">
                                                        <img style="cursor:pointer; width:40px; height:auto;" class="img-time-matters" :src="require(`@/assets/images/icons/${attachment.imageName}`)">
                                                        <div class="user-info">
                                                            <span class="tb-lead">{{attachment.name + attachment.extension}}<span class="dot dot-success d-md-none ml-1"></span></span>
                                                            <span>Attached {{$moment.utc(attachment.createdDate).locale('en').fromNow()}} by {{ attachment.createdByUser }}</span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="nk-tb-col nk-tb-col-tools">
                                                <ul class="nk-tb-actions gx-1">
                                                    <li v-on:click="deleteAttachment(attachment)" class="nk-tb-action-hidden">
                                                        <a class="btn btn-trigger btn-icon" data-toggle="tooltip" data-placement="top" title="" data-original-title="Send Email">
                                                            <em class="icon ni ni-delete"></em>
                                                        </a>
                                                    </li>
                                                    <li class="nk-tb-action-hidden">
                                                        <a v-on:click="getAttachment(attachment)" class="btn btn-trigger btn-icon" data-toggle="tooltip" data-placement="top" title="" data-original-title="Suspend">
                                                            <em class="icon ni ni-download"></em>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div><!-- .nk-tb-item -->
                                    </div>
                            </div>
                        </div>
                     </div>
                    </div>
                  </div>
              </div>
      <div class="fileUploadBx" v-if="files && files.length">
      <h5 class="text-center mt-2">Uploading...</h5>
            <ul class="mt-1"  >
            <li class="text-sm p-1" v-for="file in files" :key="`file-${file}`">
                <div class="row">
                    <div class="col-md-auto" style="padding-right: 4px;">
                        <a-icon class="text-success" style="margin-top:-3px;" type="sync" spin />
                    </div>
                    <div class="col-md" style="padding-top:14px;padding-left: 0px;">
                        <span style="font-size:11px;">{{file.name}}</span>
                    </div>
                </div>
            </li>
            </ul>
        </div>

  </div>
</template>

<script>
export default {
  watch: {
    files: function () {
            this.processFiles()
        }
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    entityName: {
      type: String,
      default: null
    },
    getEndpoint: {
      type: String,
      default: '/attachments'
    },
  },
  data () {
    return {
      files: [],
      attachments: null,
      newAttachment: {content: ''},
    }
  },
  created () {
    this.resetAttachment()
    this.getAttachments()
  },
  methods: {
      deleteAttachment (attachment) {
        this.$http.post('/files/deleteAttachment/', attachment)
          .then(() => { 
            this.getAttachments()
          })
          .catch(() => { 
             this.$message.error('There was an error deleting this attachment')
          })
      },
      getAttachment (attachment) {
        this.$http.post('/files/getAttachment/', attachment, { 'responseType': 'blob' })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
           fileLink.setAttribute('download', response.headers.filename)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch((error) => {
            if (error != null) {
                this.$message.error('There was an error fetching this attachment')
            } else {
                this.$message.error('There was an error fetching this attachment')
            }
        })
      },
    finishedUploading () {
        this.files = []
        this.getAttachments()
    },
    processFiles () {
        if (this.files.length){
            this.startUpload(this.files[0])
        }
    },
    startUpload(file) {
      this.upload(file, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
    .then(() => {
            setTimeout(() => this.finishedUploading() , 3000);
    })
    .catch((error) => {
      this.finishedUploading()
        if (error != null && error.message != null) {
            alert(error.message)
        } else {
                  alert("Could not upload the file. Please contact support!")
        }
    });
    },
    upload(file, onUploadProgress) {
    let formData = new FormData()
    formData.append('entityId', this.id)
    formData.append('entityName', this.entityName)
    formData.append("file", file)
      return this.$http.post("/files/uploadAttachment/", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      });
    },
    onChange() {
        this.files = [...this.$refs.fileUpload.files];
    },
    openFileSelect () {
            this.$refs.fileUpload.click()
    },
    resetAttachment () {
      this.files = []
      this.attachments = null
      this.newAttachment = {content: ''}
    },
    saveAttachment () {
      this.$http.post(this.saveEndpoint, this.newAttachment)
        .then(() => {
            this.getAttachments()
            this.resetAttachment()
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
    },
    getAttachments () {
       this.$http.get(this.getEndpoint)
        .then((response) => {
            this.attachments = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
    },
  },
components: {},
}
</script>

<style>
.fileUploadBx {
    padding: 0px 10px 0px 10px;
    border: 2px solid #ebeef2;
    border-radius: 10px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #fff;
}
</style>