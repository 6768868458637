<template>
    <div>
        <div class="float-right">
            <download-csv
                :data= "csvData">
                <button class="btn btn-sm btn-dark" style="margin-top: -20px; margin-bottom: 15px;">
                    <i class="icon ni ni-download" style="margin-right:5px;"></i>
                    Download
                </button>
            </download-csv>
        </div>
        <vue-good-table
            :columns="columns"
            :rows="assetAttributeRecords"
            styleClass="vgt-table condensed tblhov"
        >
            <template slot="table-row" slot-scope="props" >
                <div v-if="props.column.field == 'fromDate'">
                    {{ $moment(props.row.fromDate).format('LL') }}
                </div>
                <div v-else-if="props.row.toDate && props.column.field == 'toDate'">
                    {{ $moment(props.row.toDate).format('LL') }}
                </div>
                <div v-else-if="props.column.field === 'actions'">
                    <div class="btn-group">
                        <button class="btn btn-dark btn-xs" @click="openEdit(props)"><i class="icon ni ni-edit"></i></button>
                        <a-popconfirm title="Are you sure you want to delete this record?" ok-text="Yes" cancel-text="Cancel" @confirm="deleteRecord(props)" @cancel="cancel()">
                            <button class="btn btn-danger btn-xs"><i class="icon ni ni-trash"></i></button>
                        </a-popconfirm>
                    </div>
                </div>
            </template>
        </vue-good-table>
        <a-modal v-model="editModal">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <div class="form-label">Value</div>
                        <a-input style="width:100%;" v-model="selectedData.value"></a-input>
                    </div>
                    <div class="form-group" v-if="selectedData.fromDate">
                        <div class="form-label">From Date</div>
                        <a-date-picker style="width:100%;" format="DD/MM/YYYY" v-model="selectedData.fromDate"></a-date-picker>
                    </div>
                    <div class="form-group" v-if="selectedData.toDate">
                        <div class="form-label">To Date</div>
                        <a-date-picker style="width:100%;" format="DD/MM/YYYY" v-model="selectedData.toDate"></a-date-picker>
                    </div>
                    <div class="form-group">
                        <div class="form-label">Assigned User</div>
                        <a-select show-search v-model="selectedData.assignedToUserId" option-filter-prop="children" placeholder="Select User" style="width:100%;">
                            <a-select-option v-for="u in users" :key="u.id">
                                {{ u.firstName + ' ' + u.lastName}}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
            </div>
            <template slot="footer">
                <button class="btn btn-sm btn-success" @click="saveEdit"><i class="icon ni ni-save" style="margin-right:5px;"></i> Save</button>
            </template>
        </a-modal>
    </div>
</template>

<script>
export default {
    props: {
        filterLog: {
            type: Object
        },
        hasChanged: {
            type: Boolean
        }
    },
    data() {
        return {
            editModal: false,
            selectedData: {},
            users: [],
            columns: [
                { label: 'Value', field: 'value', sortable: false},
                { label: 'From Date', field: 'fromDate'},
                { label: 'To Date', field: 'toDate'},
                { label: 'Assigned To User', field: 'assignedToUser', sortable: false},
                { label: 'Cost', field: 'cost', sortable: false},
                { label: 'Actions', field: 'actions', sortable: false}
            ],
            assetAttributeRecords: []
        }
    },
    computed: {
        csvData() {
            let _csvData = this.assetAttributeRecords.map(obj => ({label: obj.label, value: obj.value, assignedToUser: obj.assignedToUser, fromDate: this.$moment(obj.fromDate).format('DD/MM/YYYY'), toDate: this.$moment(obj.toDate).format('DD/MM/YYYY'), cost: obj.cost}))

            return _csvData
        }
    },
    watch: {
        hasChanged: function() {
            this.getFilteredResults()
        }
    },
    created(){
        this.getFilteredResults()
        this.getUsers()
    },
    methods: {
        getUsers() {
            this.$http.get('/users/Get_Users')
            .then((response) => {
                this.users = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        cancel() {
            return false
        },
        deleteRecord(props) {
            this.$http.post('/assets/Delete_AssetAttributeRecord', props.row)
            .then(() => {
                this.$message.success('Record Deleted')
                this.getFilteredResults()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        openEdit(props) {
            this.selectedData = props.row
            this.editModal = true
        },
        saveEdit() {
            this.$http.post('/assets/Update_AssetAttributeRecord', this.selectedData)
            .then(() => {
                this.getFilteredResults()
                this.selectedData = {}
                this.editModal = false
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getFilteredResults(){
            this.$http.post('/assets/Get_FilteredAssetAttributeRecords', this.filterLog)
            .then((response) => {
                this.assetAttributeRecords = response.data
                this.setTableColumns()
            })
            .catch(() => {
                this.$message.error('There has been an error. Please check there is data logged for this asset.')
            })
        },
        setTableColumns(){
            if (this.assetAttributeRecords[0].hasFromDate != true ){
                this.columns[1].hidden = true
            }
            if (this.assetAttributeRecords[0].hasToDate != true  ){
                this.columns[2].hidden = true
            }
            if (this.assetAttributeRecords[0].hasCost != true  ){
                this.columns[4].hidden = true
            }
        }
    }
}
</script>

<style>

</style>