<template>
  <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <div class="card-inner">
                        <div class="row">
                            <div class="col-12">
                                <h6><em class="icon ni ni-coins"/> Depreciation Of {{ asset.name }}</h6>
                                <p v-if="valueStats">The depreciation matrix used is straight line method. This asset will decrease in value by £{{ valueStats[0].depreciatedAmount}} per year.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" v-if="valueStats">
                                <vue-good-table
                                    v-if="valueStats"
                                    :rows="valueStats"
                                    :columns="valueColumns"
                                >
                                </vue-good-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <assetDepreciationChart v-if="valueStats" :needsRefresh="needsRefresh" :widgetFetcher="widgetFetcher"/>
        </div>
    </div>
</template>

<script>
import assetDepreciationChart from '@/components/widgets/assets/assetDepreciationYears.vue'

export default {
    components: {
        assetDepreciationChart
    },
    props: {
        asset: {
            type: Object
        }
    },
    data() {
        return {
            widgetFetcher: { 
                id: this.$route.query.id 
            },
            needsRefresh: false,
            valueStats: null,
            valueColumns: [
                { label: 'Year', field: 'year' },
                { label: 'Value', field: 'depreciatedValue' },
                { label: 'Depreciation Amount', field: 'depreciatedAmount' },
                { label: 'Accumalated Depreciation', field: 'accumalatedDepreciation'},
                { label: 'Remaining Life', field: 'remainingLife'}
                //{ label: 'Actions', field: 'actions', tdClass: 'text-center', thClass: 'text-center', width: 200 },
            ],
        }
    },
    created() {
        this.getStats()
    },
    methods: {
        getStats(){
            this.$http.post('/widgets/Get_AssetDepreciationChart/', this.widgetFetcher)
            .then((response) => {
                this.valueStats = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style>

</style>