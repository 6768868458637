<template>
    <div>
        <a-tabs class="card card-bordered" style="padding:10px">
            <router-link :to="{name: 'assetsmanager'}" class="btn btn-dark btn-sm float-right" slot="tabBarExtraContent"><em class="icon ni ni-back-ios mr-1"></em> Back to Assets</router-link>
            <a-tab-pane key="1" tab="Details">
            <div class="row">
                <div class="col-6">
                        <div class="card border-right card-inner">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <a-button class="btn btn-success btn-sm float-right" @click="saveAsset()"><em class="icon ni ni-save"/> Save Details</a-button>
                                            <h6><em class="icon ni ni-box"/> Details</h6>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <label class="form-label">Asset Name</label>
                                            <a-input v-model="asset.name"></a-input>
                                        </div>
                                        <div class="col-4">
                                            <label class="form-label">Asset Type</label>
                                            <a-select v-model="asset.assetTypeId" style="width:100%;">
                                                <a-select-option v-for="a in assetTypes" :key="a.id">
                                                    {{ a.assetTypeName }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                        <div class="col-4">
                                            <label class="form-label">Assigned To</label><br>
                                            <a-input readOnly v-model="asset.assignedToUser" />
                                            <!-- <a-select show-search v-model="asset.assignedToUserId" readonly option-filter-prop="children" :filter-option="filterOption" placeholder="Select User" style="width:100%;">
                                                <a-select-option v-for="u in users" :key="u.id">
                                                    {{ u.firstName + ' ' + u.lastName}}
                                                </a-select-option>
                                            </a-select> -->
                                        </div>
                                        <div class="col-4">
                                            <label class="form-label">Purchased Value</label>
                                            <a-input-number v-model="asset.value" :min="0" style="width:100%;"></a-input-number>
                                        </div>
                                        <div class="col-4">
                                            <label class="form-label">Purchased Date</label>
                                            <a-date-picker style="width:100%;" format="DD/MM/YYYY" v-model="asset.purchasedDate"></a-date-picker>
                                        </div>
                                        <div class="col-4">
                                            <label class="form-label">Estimated Useful Life</label>
                                            <a-input-number v-model="asset.depreciationYears" :min="0" style="width:100%;"></a-input-number>
                                        </div>
                                        <div class="col-4">
                                            <label class="form-label">Salvage Value</label>
                                            <a-input-number v-model="asset.salvageAmount" :min="0" style="width:100%;"></a-input-number>
                                        </div>
                                        <div class="col-4">
                                            <label class="form-label">Is Active</label><br>
                                            <a-switch v-model="asset.isActive"/>
                                        </div>
                                        <div class="col-4" v-if="asset.retired">
                                            <label class="form-label">Retired Date</label>
                                            <a-date-picker style="width:100%;" disabled format="DD/MM/YYYY" v-model="asset.deletedDate"></a-date-picker>
                                        </div>
                                        <div class="col-4" v-if="asset.retired">
                                            <label class="form-label">Retired By</label>
                                            <a-input style="width:100%;" readOnly v-model="asset.deletedByUser"></a-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="preview-hr">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <h6><em class="icon ni ni-home"/> Properties</h6>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" v-for="p in asset.properties" :key="p.id">
                                            <label class="form-label">{{ p.label }}</label>
                                            <a-input v-if="p.type == 'input'" v-model="p.value"></a-input>
                                            <a-date-picker v-if="p.type == 'datepicker'" v-model="p.dateValue" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date"/>
                                            <div class="row" v-if="p.type == 'checkbox'">   
                                                <div class="col-12">
                                                    <a-checkbox v-model="p.booleanValue" style="fontSize: 20px"/>
                                                </div>
                                            </div>
                                            <a-input-number v-if="p.type == 'inputNumber'"  v-model="p.intValue" style="width:100%;" format="DD/MM/YYYY" placeholder="100"/>
                                            <div class="row" v-if="p.type == 'switch'">
                                                <div class="col-12">
                                                    <a-switch v-model="p.booleanValue" placeholder="Select Date"/>
                                                </div>
                                            </div>
                                            <a-time-picker v-if="p.type == 'timepicker'" v-model="p.dateValue" style="width: 100%;" placeholder="Select Time"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="preview-hr">
                            <div class="row">
                                <div class="col-12">
                                    <!-- <a-button class="btn btn-success btn-sm float-right" @click="saveAssetAttributes()"><em class="icon ni ni-save"/> Save Variables</a-button> -->
                                    <h6><em class="icon ni ni-link-group"/> Log Current Records</h6>
                                    <p>These are values that want to log and are shown on the right hand side.</p>
                                </div>
                            </div>
                            <div class="row" v-for="a in assetAttributeRecords" :key="a.assetAttributeId">
                                <div class="col-12">
                                    <div class="row" v-if="a.type == 'input'">
                                        <div class="col-lg">
                                            <label class="form-label">{{ a.label }}</label>
                                            <a-input v-model="a.value" @change="showSaveButton(a)" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"></a-input>
                                        </div>
                                        <div class="col-lg" v-if="a.hasFromDate">
                                            <label class="form-label" >From Date</label>
                                            <a-date-picker v-model="a.fromDate" style="width:100%;" @change="showSaveButton(a)" format="DD/MM/YYYY" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasToDate">
                                            <label class="form-label">To Date</label>
                                            <a-date-picker v-model="a.toDate" style="width:100%;" @change="showSaveButton(a)" format="DD/MM/YYYY" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasCost">
                                            <label class="form-label">Cost</label>
                                            <a-input style="width:100%;" v-model="a.cost" format="DD/MM/YYYY" @change="showSaveButton(a)" placeholder="Cost" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="showSave.assetRecordId == a.assetAttributeId && showSave.show">
                                            <br>
                                            <a-button class="btn btn-sm btn-danger float-right" style="margin-left:10px;" @click="cancelSave()"><em class="icon ni ni-cross-circle"/> Cancel</a-button>
                                            <a-button class="btn btn-sm btn-success float-right" @click="saveAssetAttribute(a)"><em class="icon ni ni-save"/> Save</a-button>
                                        </div>
                                    </div>

                                    <div class="row" v-if="a.type == 'datepicker'">
                                        <div class="col-lg">
                                            <label class="form-label">{{ a.label }}</label>
                                            <a-date-picker v-model="a.dateValue" @change="showSaveButton(a)" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasFromDate">
                                            <label class="form-label" >From Date</label>
                                            <a-date-picker v-model="a.fromDate" @change="showSaveButton(a)" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasToDate">
                                            <label class="form-label">To Date</label>
                                            <a-date-picker v-model="a.toDate" @change="showSaveButton(a)" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasCost">
                                            <label class="form-label">Cost</label>
                                            <a-input style="width:100%;" v-model="a.cost" @change="showSaveButton(a)" format="DD/MM/YYYY" placeholder="Cost" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="showSave.assetRecordId == a.assetAttributeId && showSave.show">
                                            <br>
                                            <a-button class="btn btn-sm btn-danger float-right" style="margin-left:10px;" @click="cancelSave()"><em class="icon ni ni-cross-circle"/> Cancel</a-button>
                                            <a-button class="btn btn-sm btn-success float-right" @click="saveAssetAttribute(a)"><em class="icon ni ni-save"/> Save</a-button>
                                        </div>
                                    </div>
                                    
                                    <div class="row" v-if="a.type == 'checkbox'">   
                                        <div class="col-lg">
                                            <label class="form-label">{{ a.label }}</label><br>
                                            <a-checkbox v-model="a.booleanValue" @change="showSaveButton(a)" style="fontSize: 20px" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasFromDate">
                                            <label class="form-label" >From Date</label>
                                            <a-date-picker v-model="a.fromDate" style="width:100%;" @change="showSaveButton(a)" format="DD/MM/YYYY" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasToDate">
                                            <label class="form-label">To Date</label>
                                            <a-date-picker v-model="a.toDate" style="width:100%;" @change="showSaveButton(a)" format="DD/MM/YYYY" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasCost">
                                            <label class="form-label">Cost</label>
                                            <a-input style="width:100%;" v-model="a.cost" @change="showSaveButton(a)" format="DD/MM/YYYY" placeholder="Cost" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="showSave.assetRecordId == a.assetAttributeId && showSave.show">
                                            <br>
                                            <a-button class="btn btn-sm btn-danger float-right" style="margin-left:10px;" @click="cancelSave()"><em class="icon ni ni-cross-circle"/> Cancel</a-button>
                                            <a-button class="btn btn-sm btn-success float-right" @click="saveAssetAttribute(a)"><em class="icon ni ni-save"/> Save</a-button>
                                        </div>
                                    </div>
                                    
                                    <div class="row" v-if="a.type == 'inputNumber'">
                                        <div class="col-lg">
                                            <label class="form-label">{{ a.label }}</label>
                                            <a-input-number v-model="a.intValue" @change="showSaveButton(a), setValue(a)" style="width:100%;" format="DD/MM/YYYY" placeholder="100" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasFromDate">
                                            <label class="form-label" >From Date</label>
                                            <a-date-picker v-model="a.fromDate" style="width:100%;" @change="showSaveButton(a)" format="DD/MM/YYYY" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasToDate">
                                            <label class="form-label">To Date</label>
                                            <a-date-picker v-model="a.toDate" style="width:100%;" @change="showSaveButton(a)" format="DD/MM/YYYY" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasCost">
                                            <label class="form-label">Cost</label>
                                            <a-input style="width:100%;" v-model="a.cost" @change="showSaveButton(a)" format="DD/MM/YYYY" placeholder="Cost" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="showSave.assetRecordId == a.assetAttributeId && showSave.show">
                                            <br>
                                            <a-button class="btn btn-sm btn-danger float-right" style="margin-left:10px;" @click="cancelSave()"><em class="icon ni ni-cross-circle"/> Cancel</a-button>
                                            <a-button class="btn btn-sm btn-success float-right" @click="saveAssetAttribute(a)"><em class="icon ni ni-save"/> Save</a-button>
                                        </div>
                                    </div>
                                    
                                    <div class="row" v-if="a.type == 'switch'">
                                        <div class="col-lg">
                                            <label class="form-label">{{ a.label }}</label><br>
                                            <a-switch v-model="a.booleanValue" @change="showSaveButton(a)" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasFromDate">
                                            <label class="form-label" >From Date</label>
                                            <a-date-picker v-model="a.fromDate" @change="showSaveButton(a)" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasToDate">
                                            <label class="form-label">To Date</label>
                                            <a-date-picker v-model="a.toDate" @change="showSaveButton(a)" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasCost">
                                            <label class="form-label">Cost</label>
                                            <a-input style="width:100%;" v-model="a.cost" @change="showSaveButton(a)" format="DD/MM/YYYY" placeholder="Cost" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="showSave.assetRecordId == a.assetAttributeId && showSave.show">
                                            <br>
                                            <a-button class="btn btn-sm btn-danger float-right" style="margin-left:10px;" @click="cancelSave()"><em class="icon ni ni-cross-circle"/> Cancel</a-button>
                                            <a-button class="btn btn-sm btn-success float-right" @click="saveAssetAttribute(a)"><em class="icon ni ni-save"/> Save</a-button>
                                        </div>
                                    </div>

                                    <div class="row" v-if="a.type == 'timepicker'">
                                        <div class="col-lg">
                                            <a-time-picker v-model="a.dateValue" @change="showSaveButton(a)" style="width: 100%;" placeholder="Select Time" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasFromDate">
                                            <label class="form-label" >From Date</label>
                                            <a-date-picker v-model="a.fromDate" style="width:100%;" @change="showSaveButton(a)" format="DD/MM/YYYY" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasToDate">
                                            <label class="form-label">To Date</label>
                                            <a-date-picker v-model="a.toDate" style="width:100%;" @change="showSaveButton(a)" format="DD/MM/YYYY" placeholder="Select Date" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="a.hasCost">
                                            <label class="form-label">Cost</label>
                                            <a-input style="width:100%;" v-model="a.cost" format="DD/MM/YYYY" @change="showSaveButton(a)" placeholder="Cost" :disabled="showSave.assetRecordId != a.assetAttributeId && showSave.pendingSave == true"/>
                                        </div>
                                        <div class="col-lg" v-if="showSave.assetRecordId == a.assetAttributeId && showSave.show">
                                            <br>
                                            <a-button class="btn btn-sm btn-danger float-right" style="margin-left:10px;" @click="cancelSave()"><em class="icon ni ni-cross-circle"/> Cancel</a-button>
                                            <a-button class="btn btn-sm btn-success float-right" @click="saveAssetAttribute(a)"><em class="icon ni ni-save"/> Save</a-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6" style="margin-top: 0px;">
                        <div class="card card-inner">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <h6><em class="icon ni ni-search"/> Filter</h6>
                                        <div class="row">
                                            <div class="col-3">
                                                <label class="form-label">Asset Attribute</label>
                                                <a-select style="width:100%;" v-model="filterLog.assetAttributeId" @select="changed()">
                                                    <a-select-option v-for="a in assetAttributes" :key="a.assetAttributeId" @click="setFilterAttributeType(a)">
                                                        {{ a.label }}
                                                    </a-select-option>
                                                </a-select>
                                            </div>
                                            <div class="col-3">
                                                <label class="form-label">From Date</label>
                                                <a-date-picker style="width:100%;" v-model="filterLog.fromDate" @change="changed()"></a-date-picker>
                                            </div>
                                            <div class="col-3">
                                                <label class="form-label">To Date</label>
                                                <a-date-picker style="width:100%;" v-model="filterLog.toDate" @change="changed()"></a-date-picker>
                                            </div>
                                            <div class="col-3">
                                                <label class="form-label">Assigned To User</label>
                                                <a-select style="width:100%;" v-model="filterLog.assignedToUserId" @change="changed()">
                                                    <a-select-option v-for="u in users" :key="u.id">
                                                        {{ u.firstName + ' ' + u.lastName }}
                                                    </a-select-option>
                                                </a-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style="padding-top:20px;">
                                    <div class="col-12">
                                        <hr class="preview-hr" style="margin-top:0px;">
                                        <div class="text-center" v-if="!filterLog.type">
                                            <span>Please select an Asset Attribute.</span>
                                        </div>
                                        <stringLog v-if="filterLog.type == 'input'" :filterLog="filterLog" :hasChanged="hasChanged"/>
                                        <dateLog v-if="filterLog.type == 'datepicker' || filterLog.type == 'timepicker'" :filterLog="filterLog" :hasChanged="hasChanged"/>
                                        <intLog v-if="filterLog.type == 'inputNumber'" :filterLog="filterLog" :hasChanged="hasChanged"/>
                                        <booleanLog v-if="filterLog.type == 'checkbox' || filterLog.type == 'switch'" :filterLog="filterLog" :hasChanged="hasChanged"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </a-tab-pane>
            <a-tab-pane key="2" tab="Assignments">
                <assignments :asset="asset" @getAsset="getAsset()" />
            </a-tab-pane>
            <a-tab-pane key="3" tab="Notes">
                <notes  
                    :id="$route.query.id"
                    :entityName="'asset'"
                    :getEndpoint="'/assets/Get_Asset_Notes/' + $route.query.id" 
                    :saveEndpoint="'/assets/Save_Asset_Note/'"
                    :deleteEndpoint="'/assets/Delete_Asset_Note/'">
                </notes>
            </a-tab-pane>
            <a-tab-pane key="4" tab="Attachments">
                <attachments :id="$route.query.id" :entityName="'asset'" :getEndpoint="'/assets/Get_Asset_Attachments/' + $route.query.id" />
            </a-tab-pane>
            <a-tab-pane key="5" tab="Depreciation" v-if="asset && asset.value">
                <depreciation :assetId="$route.query.id" :asset="asset" />
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import stringLog from '@/components/assets/logComponents/string.vue'
import dateLog from '@/components/assets/logComponents/date.vue'
import intLog from '@/components/assets/logComponents/int.vue'
import booleanLog from '@/components/assets/logComponents/boolean.vue'
import attachments from '@/components/generic/attachments/index.vue'
import notes from '@/components/generic/notes/index.vue'
import depreciation from '@/components/assets/depreciation.vue'
import assignments from '@/components/assets/assignments.vue'

export default {
    components: { stringLog, dateLog, intLog, booleanLog, attachments, notes, depreciation, assignments },
    data(){ 
        return {
            asset: {},
            assetAttributeRecords: [],
            assetTypes: [],
            filterOption: '',
            users: [],
            assetAttributes: [],
            showSave: {
                assetRecordId: '',
                show: false
            },
            filterLog: {},
            hasChanged: false,
        }
    },
    created() {
        this.getView()
        this.getAsset()
    },
    methods: {
        setValue(a) {
			a.intValue = a.intValue.toFixed(0)
		},
        getView() {
            this.$http.get('/ViewAccess/asset')
            .then(() => {
            })
            .catch(() => {
            })
        },
        changed(){
            if (this.hasChanged === true) {
                this.hasChanged = false
            } else {
                this.hasChanged = true
            }
        },
        cancelSave(){
            this.showSave.assetRecordId = null
            this.showSave.pendingSave = false
            this.getAssetAttributeRecords()
        },
        setFilterAttributeType(a){
            this.filterLog.assetId = this.$route.query.id
            this.filterLog.type = a.type
        },
        showSaveButton(a) {
            this.showSave.assetRecordId = a.assetAttributeId
            this.showSave.pendingSave = true
            
            if (a.assetAttributeId == this.showSave.assetRecordId){
                this.showSave.show = true
            } else {
                this.showSave.show = false
            }
        },
        getAsset(){
            this.$http.get('/assets/Get_Asset/' + this.$route.query.id)
            .then((response) => {
                this.asset = response.data
                if (this.needsRefresh == true) {
                    this.needsRefresh = false
                } else {
                    this.needsRefresh = true
                }
                this.getLists()
                this.getAssetAttributeRecords()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getAssetAttributeRecords(){
            this.$http.get('/assets/Get_AssetAttributeRecords/' + this.$route.query.id)
            .then((response) => {
                this.assetAttributeRecords = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getLists(){
            this.$http.get('/assets/Get_AssetTypes')
            .then((response) => {
                this.assetTypes = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/assets/Get_AssetAttributes/' + this.asset.assetTypeId)
            .then((response) => {
                this.assetAttributes = response.data
                this.assetAttributeRecords.assignedToUserId = this.asset.assignedToUserId 
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/users/Get_Users')
            .then((response) => {
                this.users = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        saveAsset(){
            this.asset.id = this.$route.query.id
            this.$http.post('/assets/Update_Asset', this.asset)
            .then(() => {
                this.$message.success('Asset Saved')
                this.getAsset()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        // saveAssetAttributes(a){
        //     alert(this.asset)
        //     a.assignedToUserId = this.asset.assignedToUserId
            
        //     this.$http.post('/assets/Log_AssetAttributeRecords', this.asset)
        //     .then(() => {
        //         this.$message.success('Asset Variables Saved')
        //     })
        //     .catch(() => {
        //         this.$message.error('There has been an error')
        //     })
        // },
        saveAssetAttribute(a){
            a.assignedToUserId = this.asset.assignedToUserId
            a.assetId = this.$route.query.id

            this.$http.post('/assets/Log_AssetAttributeRecords', a)
            .then(() => {
                this.$message.success('Asset Variables Saved')
                this.getAssetAttributeRecords()
                this.showSave.pendingSave = false
                this.showSave.assetRecordId = null
                this.changed()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
    }
}
</script>

<style>

</style>