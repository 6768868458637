<template>
  <div class="card-inner">
        <div class="row">
            <div class="col-12">
                <h6><em class="icon ni ni-repeat"/> Assignments</h6>
                <p>This shows the lifecycle of the asset's assignments.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">Currently Assigned To</label>
                <a-input readOnly v-model="asset.assignedToUser"/>
            </div>
            <div class="col-4">
                <label class="form-label">Assign To</label>
                <a-select show-search v-model="assignment.newAssignedToUserId" readonly option-filter-prop="children" :filter-option="filterOption" placeholder="Select User" style="width:100%;">
                    <a-select-option v-for="u in users" :key="u.id">
                        {{ u.firstName + ' ' + u.lastName}}
                    </a-select-option>
                </a-select>
            </div>
            <div class="col-4">
                <label class="form-label">Assigned Date</label>
                <a-date-picker v-model="assignment.assignmentDate" format="DD/MM/YYYY" style="width:100%"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a-button class="btn btn-success btn-sm float-right" @click="saveAssetAssignment()"><em class="icon ni ni-save"/> Save Assignment</a-button>
            </div>
        </div>
        <hr class="preview-hr">
        <div class="row">
            <div class="col-12">
                <h6><em class="icon ni ni-reload-alt"/> Previous Assignments</h6>
            </div>
            <div class="col-12">
                <vue-good-table
                    :columns="assignmentColumns"
                    :rows="assignments"
                >
                    <template slot="table-row" slot-scope="props" >
                        <div v-if="props.column.field == 'assignmentDate'">
                            {{ $moment(props.row.assignmentDate).format('LL') }}
                        </div>
                        <div v-else-if="props.column.field == 'createdDate'">
                            {{ $moment(props.row.createdDate).format('LL') }}
                        </div>
                        <div v-else-if="props.column.field == 'actions'">
                            <div class="btn-group">
                                <button class="btn btn-dark btn-sm" @click="openEdit(props)"><i class="icon ni ni-edit"></i></button>
                                <a-popconfirm title="Are you sure you want to delete this record?" ok-text="Yes" cancel-text="Cancel" @confirm="deleteRecord(props)" @cancel="cancel()">
                                    <button class="btn btn-danger btn-xs"><i class="icon ni ni-trash"></i></button>
                                </a-popconfirm>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
            </div>
        </div>
        <a-modal v-model="editModal">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <div class="form-label">Assigned To</div>
                        <a-select show-search v-model="selectedAssignment.newAssignedToUserId" readonly option-filter-prop="children" :filter-option="filterOption" placeholder="Select User" style="width:100%;">
                            <a-select-option v-for="u in users" :key="u.id">
                                {{ u.firstName + ' ' + u.lastName}}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="form-group">
                        <div class="form-label">Assigned Date</div>
                        <a-date-picker v-model="selectedAssignment.assignmentDate" format="DD/MM/YYYY" style="width: 100%;"></a-date-picker>
                    </div>
                </div>
            </div>
            <template slot="footer">
                <button class="btn btn-sm btn-success" @click="saveEdit"><i class="icon ni ni-save" style="margin-right: 5px;"></i> Save</button>
            </template>
        </a-modal>
    </div>
</template>

<script>
export default {
    props: {
        asset: {
            type: Object
        }
    },
    data() {
        return {
            editModal: false,
            filterOption: '',
            assignment: {},
            selectedAssignment: {},
            assignmentColumns: [
                { label: 'Was Assigned To', field: 'oldAssignedToUser' },
                { label: 'Assigned To', field: 'newAssignedToUser' },
                { label: 'Assigned Date', field: 'assignmentDate' },
                { label: 'Logged On', field: 'createdDate'},
                { label: 'Logged By', field: 'createdByUser'},
                { label: 'Actions', field: 'actions', tdClass: 'text-center', thClass: 'text-center', width: 200 },
            ],
            assignments: [],
            users: null,
        }
    },
    created() {
        this.getAssetAssignments()
        this.getUsers()
    },
    methods: {
        deleteRecord(props) {
            this.$http.post('/assets/Delete_AssetAssignment', props.row)
            .then(() => {
                this.$message.success('Asset Assignment Deleted')
                this.getAssetAssignments()
                this.$emit('getAsset')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        openEdit(props) {
            this.selectedAssignment = props.row
            this.editModal = true
        },
        saveEdit() {
            this.$http.post('/assets/Update_AssetAssignment', this.selectedAssignment)
            .then(() => {
                this.selectedAssignment = {}
                this.editModal = false
                this.$message.success('Asset Assignment Saved')
                this.getAssetAssignments()
                this.$emit('getAsset')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getAssetAssignments() {
            this.$http.get('/assets/Get_Asset_Assignments/' + this.$route.query.id)
            .then((response) => {
                this.assignments = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        saveAssetAssignment(){
            this.assignment.assetId = this.$route.query.id
            this.$http.post('/assets/Add_Asset_Assignment', this.assignment)
            .then(() => {
                this.asset.assignedToUserId = this.assignment.newAssignedToUserId
                this.assignment.newAssignedToUserId = null
                this.$message.success('Asset Assignment Saved')
                this.getAssetAssignments()
                this.$emit('getAsset')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getUsers() {
            this.$http.get('/users/Get_Users')
            .then((response) => {
                this.users = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style>

</style>